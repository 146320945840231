import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_ROUTES: Routes = [
    // {
    //     // path: 'pages',
    //     // loadChildren: () => import('../../pages/content-pages/content-pages.module').then(m => m.ContentPagesModule)
    // }
    {
        path: 'maintenance-mode',
        loadChildren: () => import('../../maintenance-mode/maintenance-mode.module').then(m => m.MaintenanceModeModule)
    },
    {
        path: 'page-not-found',
        loadChildren: () => import('../../page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
    },
    {
        path: 'login',
        loadChildren: () => import('../../login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'login/:id',
        loadChildren: () => import('../../login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'register',
        loadChildren: () => import('../../register/register.module').then(m => m.RegisterModule)
    },
    {
        path: 'register/:role',
        loadChildren: () => import('../../register/register.module').then(m => m.RegisterModule)
    },
    {
        path: "forgot_password",
        loadChildren: () =>
            import("../../forgot-password/forgot-password.module").then(
                m => m.ForgotPasswordModule
            )
    },
    {
        path: "reset_password/:token",
        loadChildren: () =>
            import("../../reset-password/reset-password.module").then(
                m => m.ResetPasswordModule
            )
    },
    {
        path: 'test-paypal',
        loadChildren: () => import('../../test-paypal/test-paypal.module').then(m => m.TestPaypalModule)
    },
    {
        path: 'test-qrcode-scanner',
        loadChildren: () => import('../../test-qrcode-scanner/test-qrcode-scanner.module').then(m => m.TestQrcodeScannerModule)
    }
];
