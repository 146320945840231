<ngx-spinner
bdOpacity = 0.4
bdColor = "rgba(51,51,51,0.83)"
size = "medium"
color = "#169b9e"
type = "ball-atom"
[fullScreen] = "true"
>
<p style="color: white" > </p>
</ngx-spinner>

<router-outlet></router-outlet>