import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
         {
           path: "",
           data: { role: "platform-owner" },
           loadChildren: () =>
             import("../../platform-owner/platform-owner.module").then(
               m => m.PlatformOwnerModule
             )
         },
         {
           path: "",
           data: { role: "account-admin" },
           loadChildren: () =>
             import("../../account-admin/account-admin.module").then(
               m => m.AccountAdminModule
             )
         },
         {
           path: "",
           data: { role: "verifier" },
           loadChildren: () =>
             import("../../verifier/verifier.module").then(
               m => m.VerifierModule
             )
         },
         {
           path: "",
           data: { role: "recipient" },
           loadChildren: () =>
             import("../../recipient/recipient.module").then(
               m => m.RecipientModule
             )
         },

         {
           path: "",
           data: { role: "distributor" },
           loadChildren: () =>
             import("../../distributor/distributor.module").then(
               m => m.DistributorModule
             )
         }

         // {
         //   path: "profile",
         //   loadChildren: () =>
         //     import("../../profile/profile.module").then(m => m.ProfileModule)
         // },
         /* {
     path: 'calendar',
     loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
   },
   {
     path: 'charts',
     loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)
   },
   {
     path: 'forms',
     loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
   },
   {
     path: 'maps',
     loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)
   },
   {
     path: 'tables',
     loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)
   },
   {
     path: 'datatables',
     loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
   },
   {
     path: 'uikit',
     loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
   },
   {
     path: 'components',
     loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
   },
   {
     path: 'pages',
     loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
   },
   {
     path: 'cards',
     loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)
   },
   {
     path: 'colorpalettes',
     loadChildren: () => import('../../color-palette/color-palette.module').then(m => m.ColorPaletteModule)
   },
   {
     path: 'chat',
     loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
   },
   {
     path: 'chat-ngrx',
     loadChildren: () => import('../../chat-ngrx/chat-ngrx.module').then(m => m.ChatNGRXModule)
   },
   {
     path: 'inbox',
     loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
   },
   {
     path: 'taskboard',
     loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
   },
   {
     path: 'taskboard-ngrx',
     loadChildren: () => import('../../taskboard-ngrx/taskboard-ngrx.module').then(m => m.TaskboardNGRXModule)
   },
   {
     path: 'player',
     loadChildren: () => import('../../player/player.module').then(m => m.PlayerModule)
   }, 

   {
     path: "settings",
     loadChildren: () =>
       import("../../settings/settings.module").then(
         m => m.SettingsModule
       )
   },
   {
     path: "profileView",
     loadChildren: () =>
       import("../../profile/profile.module").then(m => m.ProfileModule)
   },
   {
     path: "users",
     loadChildren: () =>
       import("../../users/users.module").then(m => m.UsersModule)
   },
   */
       ];
