import { Component, HostBinding } from "@angular/core";
import { CommonService } from "app/services/common.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  //Variables
  currentDate: Date = new Date();
  siteSettingsPublic: any = {};

  constructor(private commonService: CommonService) {}

  async ngOnInit() {
    let _siteSettingPromise = await this.commonService.getSiteSettingsPublic().toPromise();
    this.siteSettingsPublic = _siteSettingPromise.data;
  }
}
