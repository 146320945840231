import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from './services/common.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;


    constructor(private router: Router,
        private spinner: NgxSpinnerService,
        private commonService: CommonService,
        private title: Title) {

        var asyncLoadCount = 0;
        router.events.subscribe(
            (event: RouterEvent): void => {

                if (event instanceof RouteConfigLoadStart) {

                    asyncLoadCount++;

                } else if (event instanceof RouteConfigLoadEnd) {

                    asyncLoadCount--;

                }

                // If there is at least one pending asynchronous config load request,
                // then let's show the loading indicator.
                // --
                // CAUTION: I'm using CSS to include a small delay such that this loading
                // indicator won't be seen by people with sufficiently fast connections.

                // if (asyncLoadCount > 0) {
                //     this.spinner.show();
                // }
                // else {
                //     this.spinner.hide();
                // }

            }
        );


    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));

        /* Set Title  */
        this.title.setTitle("Uniblocks");

        /* this.commonService.hookToSiteSettings().subscribe((_data: any) => {
            // console.log(_data,"_data")
            if (_data != null) {
                this.title.setTitle(_data.platformName);
            }
            else {
                this.title.setTitle("Uniblocks");
            }
        }); */
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}