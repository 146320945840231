import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private appConfig: any = false;

  constructor(private http: HttpClient) {
    this.appConfig = environment.appConfig;
  }

  apiBaseUrl() {
    return this.appConfig.apiUrl;
  }

  passwordPattern() {
    return this.appConfig.passwordPattern;
  }
}
