import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { ConfigService } from "./config.service";
import { LocalStorage } from 'ngx-store';

@Injectable({
  providedIn: "root"
})
export class AuthService {
  baseUrl: any;
  @LocalStorage() auth: any;

  constructor(private http: HttpClient, private config: ConfigService) {
    this.baseUrl = this.config.apiBaseUrl();
  }

  isAuthenticated(){
    if(this.auth == null)
    {
      return false;
    }

    if(this.auth.token == null)
    {
      return false;
    }

    return true;
  }

  /*Admin and User Login */
  loginByApiKey(_apiKey) {
    let url = this.baseUrl + "auth/login_by_api_key";

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:''
      })
    };

    return this.http.post(url, {apiKey:_apiKey}, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(_error => {
        return throwError(_error.error);
      })
    );
  }

  /*Admin and User Login */
  login(frmData) {
    let url = this.baseUrl + "auth/login";

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:''
      })
    };

    return this.http.post(url, frmData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(_error => {
        return throwError(_error.error);
      })
    );
  }

  /*Admin and User register */
  register(frmData) {
    let url = this.baseUrl + "auth/register";

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization:''
      })
    };

    return this.http.post(url, frmData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(_error => {
        return throwError(_error.error);
      })
    );
  }

  /* Forgot_Password */
  forgot_password(frmData) {
    let url = this.baseUrl + "auth/forgot_password";

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, frmData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(_error => {
        return throwError(_error.error);
      })
    );
  }

  /* Reset_Password */
  reset_password(frmData) {

    let url = this.baseUrl + "auth/reset_password";

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, frmData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(_error => {
        return throwError(_error.error);
      })
    );
  }

  /* User Email Verifier */
  verifyEmail(id) {

    let url = this.baseUrl + "auth/verify_email/"+ id;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    return this.http.post(url, {}, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(_error => {
        return throwError(_error.error);
      })
    );
  }

  overrideVerifyEmail(id) {

    let url = this.baseUrl + "auth/override_verify_email/"+ id;

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
         Authorization: this.auth.token
      })
    };

    return this.http.post(url, {}, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(_error => {
        return throwError(_error.error);
      })
    );
  }

  isEmailVerified() {
    let url = this.baseUrl + "auth/is_email_verified";

    const httpOptions = {
      headers: new HttpHeaders({
        // "Content-Type": "application/x-www-form-urlencoded",
        Authorization: this.auth.token
      })
    };

    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError(_error => {
        return throwError(_error.error);
      })
    );
  }
  
}
