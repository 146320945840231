import { AuthService } from './../../services/auth.service';
import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from "./../../services/common.service";
import { AlertService } from "./../../services/alert.service";
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  layoutSub: Subscription;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  public config: any = {};

  //Given profile url
  profileUrl: any;
  notificationUrl: any;
  activeRole: any;
  notificationUnreadCount: any;
  account: any = "";
  contentElement: any;
  isEmailVerified: boolean = false;
  isMobile: any;

  generalInstanceSetting: any = {
    privateInstanceSSHCredentials: {}
  };

  isLoadedVerifierEmailVerification: Boolean = false;

  constructor(
    public translate: TranslateService,
    private authService: AuthService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private router: Router,
    private commonService: CommonService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private deviceService: DeviceDetectorService
  ) {
    this.isMobile = this.deviceService.isMobile();
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

    this.layoutSub = layoutService.changeEmitted$.subscribe(direction => {
      const dir = direction.direction;
      if (dir === "rtl") {
        this.placement = "bottom-left";
      } else if (dir === "ltr") {
        this.placement = "bottom-right";
      }
    });

    //Set profile url as per active user role
    this.activeRole = localStorage.getItem("activeRole");
    //   this.currentUser = activatedRoute.snapshot['_urlSegment'].segments[0].path;
    this.profileUrl = `/${this.activeRole}/profile`;
    this.notificationUrl = `/${this.activeRole}/notifications`;
    // this.currentUser = activeRole;
  }

  ngOnInit() {
    this.notificationUnreadCount = 0;
    this.config = this.configService.templateConf;

    if (this.activeRole == 'distributor') {
      setInterval((func) => {
        this.commonService.getUnreadNotificatoinsCount().subscribe((response: any) => {
          if (response.status == "success") {
            this.notificationUnreadCount = response.data.unReadCount;
          }
        })
      }, 3000);
    }

    /* Get Account Settings if activeRole is account admin - Redirect to Instance setting if user option is or 3 and user has not filled the details */
    if (this.activeRole == 'account-admin') {
      this.commonService.getAccountSettings().subscribe((response: any) => {
        if (response.status == "success") {
          this.account = response.data;
          this.generalInstanceSetting = response.data.general_instance_setting;

          if (this.account.isPrivateAppInterfaceActive == true) {
            for (let _index in this.generalInstanceSetting) {
              if (this.generalInstanceSetting[_index] == null || this.generalInstanceSetting[_index] == "Not available" || this.generalInstanceSetting[_index] == "" || this.generalInstanceSetting[_index] == "--") {
                this.router.navigate([`/${this.activeRole}/profile`]);
                this.alertService.showError('Please fill instance setting data');
                return;
              }
            }
          }
        }
      }),
        error => {
          this.alertService.showError(error.message);
        };
    }
    /*Get Account Settings if activeRole is account admin (END) */

    /* Get email is verified or not (START) */
    if (this.activeRole == 'verifier') {
      this.authService.isEmailVerified().subscribe((response: any) => {
        if (response.status == "success") {
          this.isLoadedVerifierEmailVerification = true;
          let userData = response.data;
          this.isEmailVerified = userData.isEmailVerified;
        }
      }),
        error => {
          this.alertService.showError(error.message);
        };
    }
    /* Get email is verified or not (END) */

  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      }, 0);
    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    if(this.isMobile == true)
    {
      this.contentElement = (document.querySelector('.app-sidebar') as HTMLElement);
      this.contentElement.classList.remove("hide-sidebar");
    }
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  logout() {
   
     localStorage.removeItem('ngx_auth');
     localStorage.removeItem('activeRole');
     this.router.navigate(["/login"]);
  }

  // go to notification link
  goToNotification() {
    this.router.navigate([this.notificationUrl]);
  }
}
