import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    return new Promise(async (_resolve, _reject) => {

      /* Check Authentication */
      if (this.authService.isAuthenticated()) {
        _resolve(true);
      } else {
        this.router.navigate(['/login']);
        _resolve(false);
      }
    })

  }
}
