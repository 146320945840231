import { CanActivate, ActivatedRouteSnapshot, Router,ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { CommonService } from 'app/services/common.service';
import { AuthService } from 'app/services/auth.service';
import { LocalStorage } from 'ngx-store'



@Injectable()
export class CommonGuard implements CanActivate {

    constructor(
        private router: Router,
        private commonService: CommonService,
        private route: ActivatedRoute,

        private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Promise<boolean> {

        return new Promise(async (_resolve, _reject) => {
            /* Check Maintenance Mode */
            try {
                let _role =  localStorage.getItem("activeRole");               
                let _siteSettingResponse = await this.commonService.getSiteSettingsPublic().toPromise();
                if (_siteSettingResponse['status'] == 'success' && _siteSettingResponse['data']['isMaintenanceMode'] == true &&_role!='platform-owner') {
                    this.router.navigate(['/maintenance-mode']);
                    _resolve(false);
                }

                _resolve(true);
            }
            catch (e) {
                console.log(e, "e");
                _resolve(true);
            }



        });

    }
}
