import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { map, catchError } from "rxjs/operators";
import { throwError, BehaviorSubject } from "rxjs";
import { ConfigService } from "./config.service";
import { LocalStorage } from 'ngx-store';
import { Title } from '@angular/platform-browser';

@Injectable({
	providedIn: "root"
})
export class CommonService {
	baseUrl: any;
	@LocalStorage() auth: any;

	siteSettingObservable: BehaviorSubject<any> = new BehaviorSubject(null);

	constructor(private http: HttpClient, private config: ConfigService, private titleService: Title) {
		this.baseUrl = this.config.apiBaseUrl();
	}

	// Set page title
	async setPageTitle(_title : string)
	{
		// get site title
		let siteSetting = await this.getSiteSettingsPublic().toPromise();
						
		if(siteSetting.data.platformName != null)
		{
			this.titleService.setTitle( siteSetting.data.platformName + ' - ' + _title);
		}
		else
		{
			this.titleService.setTitle( _title);
		}  
		
	}

	/*Get Account settings Start Here*/
	getAccountSettings() {
		let url = this.baseUrl + "common/account_settings";

		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/x-www-form-urlencoded",
				Authorization: this.auth.token
			})
		};

		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(_error => {
				return throwError(_error.error);
			})
		);
	}
	/*Get Account settings End Here*/

	/* Update Account settings start here */
	updateAccountSettings(frmData) {

		let url = this.baseUrl + "common/account_settings/update";

		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/json",
				Authorization: this.auth.token
			})
		};

		return this.http.post(url, frmData, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(_error => {
				return throwError(_error.error);
			})
		);
	}
	/* Update Account settings end here */

	/* Update password start here */
	updatePassword(frmData) {
		let url = this.baseUrl + "common/password/update";

		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
				Authorization: this.auth.token
			})
		};

		return this.http.post(url, frmData, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(_error => {
				return throwError(_error.error);
			})
		);
	}
	/* Update password end here */

	hookToSiteSettings() {
		return this.siteSettingObservable.asObservable();
	}

	syncSiteSettings(_data) {
		this.siteSettingObservable.next(_data);
	}

	getSiteSettingsPublic() {
		let url = this.baseUrl + "platform-owner/platform_settings/public";


		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/x-www-form-urlencoded",
				// Authorization: _token
			})
		};

		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				this.syncSiteSettings(response.data);
				return response;
			}),
			catchError(_error => {
				console.log(_error, "_error");
				return throwError(_error.error);
			})
		);
	}

	/* Get site settings start here */
	getSiteSettings() {
		let url = this.baseUrl + "platform-owner/platform_settings";

		let _token = this.auth == null ? null : this.auth.token;

		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/x-www-form-urlencoded",
				Authorization: _token
			})
		};

		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				this.syncSiteSettings(response.data);
				return response;
			}),
			catchError(_error => {
				console.log(_error, "_error");
				return throwError(_error.error);
			})
		);
	}
	/* Get site settings end here */

	/* Update site settings start here */
	updateSiteSettings(frmData) {

		let url = this.baseUrl + "platform-owner/platform_settings/update";

		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/json",
				Authorization: this.auth.token
			})
		};

		return this.http.post(url, frmData, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(_error => {
				return throwError(_error.error);
			})
		);
	}
	/* Update site settings end here */

	getCertificateDetails(certificateId) {

		let url = this.baseUrl + "common/recipient/certificate/details/" + certificateId;

		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/x-www-form-urlencoded",
				Authorization: this.auth.token
			})
		};

		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(_error => {
				return throwError(_error.error);
			})
		);
	}

	updateInstanceSettings(frmData) {
		let url = this.baseUrl + "common/instance_setting/update";

		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/json",
				Authorization: this.auth.token
			})
		};

		return this.http.post(url, frmData, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(_error => {
				return throwError(_error.error);
			})
		);
	}

	initPrivateInstance(frmData) {

		let url = this.baseUrl + "common/init_private_instance";

		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/x-www-form-urlencoded",
				Authorization: this.auth.token
			})
		};

		return this.http.post(url, frmData, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(_error => {
				return throwError(_error.error);
			})
		);
	}

	updateRepositories(frmData) {

		let url = this.baseUrl + "common/update_repositories";

		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/x-www-form-urlencoded",
				Authorization: this.auth.token
			})
		};

		return this.http.post(url, frmData, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(_error => {
				return throwError(_error.error);
			})
		);
	}

	// Get user notifications
	getNotifications() {
		let url = this.baseUrl + "common/notification_listing";
		
		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/x-www-form-urlencoded",
				Authorization: this.auth.token
			})
		};

		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				this.syncSiteSettings(response.data);
				return response;
			}),
			catchError(_error => {
				console.log(_error, "_error");
				return throwError(_error.error);
			})
		);
	}

	// Update user notifications - mark as read
	updateNotificationAsRead(){
		let url = this.baseUrl + "common/upate_notification_status";
		
		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/x-www-form-urlencoded",
				Authorization: this.auth.token
			})
		};

		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				this.syncSiteSettings(response.data);
				return response;
			}),
			catchError(_error => {
				console.log(_error, "_error");
				return throwError(_error.error);
			})
		);
	}

	// Get unread notifications
	getUnreadNotificatoinsCount() {
		let url = this.baseUrl + "common/get_unread_notifications_count";
		
		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/x-www-form-urlencoded",
				Authorization: this.auth.token
			})
		};

		return this.http.get(url, httpOptions).pipe(
			map((response: any) => {
				this.syncSiteSettings(response.data);
				return response;
			}),
			catchError(_error => {
				console.log(_error, "_error");
				return throwError(_error.error);
			})
		);
	}

	/* Update Blockchain Mode start here */
	updateBlockchainMode(frmData) {

		let url = this.baseUrl + "common/update_blockchain_mode";

		const httpOptions = {
			headers: new HttpHeaders({
				// "Content-Type": "application/json",
				Authorization: this.auth.token
			})
		};

		return this.http.post(url, frmData, httpOptions).pipe(
			map((response: any) => {
				return response;
			}),
			catchError(_error => {
				return throwError(_error.error);
			})
		);
	}
	/* Update Blockchain Mode end here */

}
